<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="venueCondition.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="venueCondition.org_id"
                                :options="orgList"
                                :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                            <ValidationProvider name="Condition (En)" vid="condition_en" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="condition_en"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_config.condition_en')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                    id="condition_en"
                                    v-model="venueCondition.condition_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Condition (Bn)" vid="condition_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="condition_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_config.condition_bn')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                    id="condition_bn"
                                    v-model="venueCondition.condition_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { venueConditionStore, venueConditionUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      const tmp = this.getvenueCondition()
      this.venueCondition = tmp
    } else {
      this.venueCondition = Object.assign({}, this.venueCondition, {
        org_id: this.$store.state.dataFilters.orgId
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      venueCondition: {
        id: '',
        fiscal_year_id: 0,
        org_id: 0,
        condition_en: '',
        condition_bn: '',
        status: 1
      }
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  methods: {
    getvenueCondition () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.venueCondition.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${venueConditionUpdate}/${this.id}`, this.venueCondition)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, venueConditionStore, this.venueCondition)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
